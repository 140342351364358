<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group row">
          <reg-input-text
            v-if="fields[0]"
            label="Company TAX ID"
            type="number"
            :form="form"
            :field="fields[0]"
            placeholder="Enter Company TAX ID"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[0].required, digits:9 }"
            @inputHandler="handleTaxInput"
          />
          <reg-input-text
            v-if="fields[1]"
            label="Company Name"
            :form="form"
            :field="fields[1]"
            placeholder="Enter Company Name"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[1].required }"
          />
        </div>
        <div class="form-group row">
          <reg-input-text
            v-if="fields[2]"
            label="Company Street Address"
            :form="form"
            :field="fields[2]"
            placeholder="Enter Company Street Address"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[2].required }"
          />

          <reg-input-text
            v-if="fields[3]"
            label="Apt / Suite / Other"
            :form="form"
            :field="fields[3]"
            placeholder=""
            :api-errors="apiErrors"
            formGroupClass="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[3].required }"
          />

          <reg-input-select
            v-if="fields[5]"
            label="State"
            :form="form"
            :field="fields[5]"
            :options="states.map((type) => type.abbrev)"
            :custom-label="(opt) => states.find((x) => x.abbrev == opt).name"
            formGroupClass="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4"
            placeholder="Choose a State"
            :api-errors="apiErrors"
            :rules="{ required: fields[5].required }"
          />
        </div>

        <div class="form-group row">
          <reg-input-text
            v-if="fields[4]"
            label="Company City"
            :form="form"
            :field="fields[4]"
            placeholder="Enter Company City"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[4].required }"
          />
          <reg-input-text
            v-if="fields[6]"
            label="Company Zip Code"
            :form="form"
            :field="fields[6]"
            placeholder="Enter Company Zip Code"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[6].required, digits:5 }"
            type="number"
            @inputHandler="handleZipInput"
          />
        </div>

        <div class="form-group">
          <div class="mb-4 form-check">
            <input
              class="form-check-input"
              v-if="fields[7] && fields[7].field_name"
              type="checkbox"
              id="flexCheckDefault"
              :class="
                apiErrors && apiErrors[fields[7].field_name]
                  ? 'error-input'
                  : ''
              "
              :disabled="fields[5] && fields[7].readOnly"
              v-model="form[fields[7].field_name]"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Validate with USPS
            </label>
            <error-text
              v-if="apiErrors && apiErrors[fields[7].field_name]"
              :errors="apiErrors"
              :field="fields[7].field_name"
            />
          </div>
        </div>
        <div class="action-btn split-action mt-5">
          <button class="button-back" @click="backHandler">Back</button>
          <div class="action-next">
            <button class="button-save" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-submit" type="submit">Next</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import axios from "@/utils/regAxios";
import ScreenShot from "@/utils/screenshot";

export default {
  name: "BusinessForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
    states: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
  }),
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackBusinessInfoForm");
    },
    handleTaxInput(event) {
    let inputValue = event.target.value;
    if (inputValue.length > 9) {
      inputValue = inputValue.slice(0, 9);
      this.form['tax_id'] = inputValue;
    }
  },
    handleZipInput(event) {
    let inputValue = event.target.value;
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5);
      this.form['zip'] = inputValue;
    }
  },
    submit() {
      this.form.registration_id = this.$store.getters.regTempId;
      this.form[this.fields[7].field_name] =
        this.form[this.fields[7].field_name] === "true" ||
        this.form[this.fields[7].field_name] == true
          ? true
          : false;
      let loader = this.$loading.show();
      let formData = this.form;
      ScreenShot.captureScreenShot(ScreenShot.regScreenShotSelector)
        .then((res) => {
          if (res) {
            formData["screenshot"] = res;
          }
        })
        .finally(() => {
          loader.hide();
          const url = "v2/registration/business-info";
          this.save(url, formData);
        });
    },
    save(url, payload) {
      axios
        .post(url, payload)
        .then(() => {
          // this.$notify(res.data.message);
          this.$emit("onSuccessBusinessInfoForm");
        })
        .catch((err) => {
          if (err.response.data.statusCode == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        });
    },
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
  watch: {
    form() {
      this.form[this.fields[7].field_name] =
        this.form[this.fields[7].field_name] === "true" ||
        this.form[this.fields[7].field_name] == true
          ? true
          : false;
    },
  },
};
</script>