var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row mt-4"
        }, [_vm.fields[0] ? _c('reg-input-text', {
          attrs: {
            "label": "Email Address",
            "type": "email",
            "form": _vm.form,
            "field": _vm.fields[0],
            "placeholder": "Enter Email Address",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-12 mb-4",
            "rules": {
              required: _vm.fields[0].required,
              email: true
            }
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "d-flex align-items-center mb-3"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.isChecked,
            expression: "isChecked"
          }],
          staticClass: "mb-1",
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.isChecked) ? _vm._i(_vm.isChecked, null) > -1 : _vm.isChecked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.isChecked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = null,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && (_vm.isChecked = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.isChecked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.isChecked = $$c;
              }
            }
          }
        }), _c('label', {
          staticClass: "mb-0 ms-2"
        }, [_vm._v("Display Email Address")])]), _vm.isChecked ? _c('div', {
          staticClass: "form-group row mt-2"
        }, [_vm.fields[3] ? _c('reg-input-text', {
          attrs: {
            "label": "Display Email Address",
            "type": "email",
            "form": _vm.form,
            "field": _vm.fields[3],
            "placeholder": "Enter New Email Address",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-12 mb-4"
          }
        }) : _vm._e()], 1) : _vm._e(), _c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[1] ? _c('reg-input-text', {
          attrs: {
            "label": "Password",
            "type": "password",
            "form": _vm.form,
            "field": _vm.fields[1],
            "placeholder": "Password",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[1].required
            }
          },
          scopedSlots: _vm._u([{
            key: "appendIcon",
            fn: function fn() {
              return [_c('eye-button', {
                attrs: {
                  "id": "password",
                  "class-name": "toggle-password-icon",
                  "hide-on-default": true,
                  "type": "password"
                }
              })];
            },
            proxy: true
          }, {
            key: "shortMessage",
            fn: function fn() {
              return [!(_vm.apiErrors && _vm.apiErrors[_vm.fields[1].field_name]) ? _c('span', {
                staticClass: "short-msg"
              }, [_vm._v(" At least 8 Characters: One Uppercase Letter, One Lowercase Letter, One Number and One Special Character ")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }) : _vm._e(), _vm.fields[2] ? _c('reg-input-text', {
          attrs: {
            "label": "Confirm Password",
            "type": "password",
            "form": _vm.form,
            "field": _vm.fields[2],
            "placeholder": "Confirm Password",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[2].required
            }
          },
          scopedSlots: _vm._u([{
            key: "appendIcon",
            fn: function fn() {
              return [_c('eye-button', {
                attrs: {
                  "id": "password_confirmation",
                  "class-name": "toggle-password-icon",
                  "hide-on-default": true,
                  "type": "password"
                }
              })];
            },
            proxy: true
          }], null, true)
        }) : _vm._e()], 1), _c('div', {
          staticClass: "action-btn split-action mt-5"
        }, [_c('button', {
          staticClass: "button-back",
          on: {
            "click": _vm.backHandler
          }
        }, [_vm._v("Back")]), _c('div', {
          staticClass: "action-next"
        }, [_c('button', {
          staticClass: "button-save",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveAndContinue.apply(null, arguments);
            }
          }
        }, [_vm._v(" Save & Continue Later ")]), _c('button', {
          staticClass: "button-submit",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Next")])])])])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }