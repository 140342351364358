var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row mt-4"
        }, [_vm.fields[0] ? _c('reg-input-text', {
          attrs: {
            "label": "First Name",
            "form": _vm.form,
            "field": _vm.fields[0],
            "placeholder": "Enter First Name",
            "api-errors": _vm.apiErrors,
            "rules": {
              required: _vm.fields[0].required
            }
          }
        }) : _vm._e(), _vm.fields[1] ? _c('reg-input-text', {
          attrs: {
            "label": "Middle Initial",
            "form": _vm.form,
            "field": _vm.fields[1],
            "placeholder": "Enter Middle Initial",
            "api-errors": _vm.apiErrors,
            "rules": {
              required: _vm.fields[1].required,
              alpha: true,
              max: 1
            }
          },
          on: {
            "inputHandler": _vm.handleMiddleInitialInput
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[2] ? _c('reg-input-text', {
          attrs: {
            "label": "Last Name",
            "form": _vm.form,
            "field": _vm.fields[2],
            "placeholder": "Enter Last Name",
            "api-errors": _vm.apiErrors,
            "rules": {
              required: _vm.fields[2].required
            }
          }
        }) : _vm._e(), _vm.fields[3] && _vm.regType === 'individual' ? _c('reg-input-text', {
          attrs: {
            "label": "SSN",
            "form": _vm.form,
            "field": _vm.fields[3],
            "placeholder": "Enter SSN Number",
            "api-errors": _vm.apiErrors,
            "rules": {
              required: _vm.fields[3].required,
              digits: 9
            },
            "type": "number"
          },
          on: {
            "inputHandler": _vm.handleSSNInput
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[4] && _vm.regType === 'individual' ? _c('reg-input-date-picker', {
          attrs: {
            "label": "Date of Birth",
            "form": _vm.form,
            "field": _vm.fields[4],
            "api-errors": _vm.apiErrors,
            "placeholder": "MM/DD/YYYY",
            "date-format": "MM/DD/YYYY",
            "rules": {
              required: _vm.fields[4].required
            }
          }
        }) : _vm._e(), _c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
        }, [_vm.fields[5] ? _c('label', [_vm._v("What do you consider your main expertise in? "), _c('span', {
          staticClass: "required-color"
        }, [_vm._v("*")])]) : _vm._e(), _vm.fields[5] ? _c('RegExpertiesSelect', {
          attrs: {
            "form": _vm.form,
            "vmodel": _vm.form.experties_in,
            "field": _vm.fields[5],
            "name": "state",
            "labelName": "Experties In",
            "api-errors": _vm.apiErrors,
            "placeholder": "Select Experties",
            "options": _vm.options.map(function (type) {
              return type.code;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.options.find(function (x) {
                return x.code == opt;
              }).name;
            },
            "rules": {
              required: _vm.fields[5].required
            }
          }
        }) : _vm._e()], 1)], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
        }, [_vm.fields[6] ? _c('label', [_vm._v("What products do you sell for us to serve you better in the future? "), _c('span', {
          staticClass: "required-color"
        }, [_vm._v("*")])]) : _vm._e(), _vm.fields[6] ? _c('RegProductSelect', {
          key: "code",
          staticClass: "reg_product",
          attrs: {
            "form": _vm.form,
            "field": _vm.fields[6],
            "api-errors": _vm.apiErrors,
            "labelName": "Product Sold",
            "track-by": "code",
            "options": _vm.products.map(function (type) {
              return type.code;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.products.find(function (x) {
                return x.code == opt;
              }).name;
            },
            "placeholder": "Select Product Sold",
            "rules": {
              required: _vm.fields[6].required
            }
          },
          model: {
            value: _vm.form.product_sold,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "product_sold", $$v);
            },
            expression: "form.product_sold"
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-6"
        }, [_vm.fields[7] ? _c('label', [_vm._v("How long have you been in the business? "), _c('span', {
          staticClass: "required-color"
        }, [_vm._v("*")])]) : _vm._e(), _vm.fields[7] ? _c('RegBusinessSelect', {
          attrs: {
            "field": _vm.fields[7],
            "labelName": "business experience",
            "vmodel": _vm.form.years_in_business,
            "form": _vm.form,
            "api-errors": _vm.apiErrors,
            "placeholder": "Select Business Experience",
            "options": _vm.Years.map(function (type) {
              return type.code;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.Years.find(function (x) {
                return x.code == opt;
              }).name;
            },
            "rules": {
              required: _vm.fields[7].required
            }
          }
        }) : _vm._e()], 1)]), _c('div', {
          staticClass: "action-btn split-action mt-3"
        }, [_c('button', {
          staticClass: "button-back",
          on: {
            "click": _vm.backHandler
          }
        }, [_vm._v("Back")]), _c('div', {
          staticClass: "action-next"
        }, [_c('button', {
          staticClass: "button-save",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveAndContinue.apply(null, arguments);
            }
          }
        }, [_vm._v(" Save & Continue Later ")]), _c('button', {
          staticClass: "button-submit",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Next")])])])])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }