<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="form-group row mt-4">
        <reg-input-text
          v-if="fields[0]"
          label="First Name"
          :form="form"
          :field="fields[0]"
          placeholder="Enter First Name"
          :api-errors="apiErrors"
          :rules="{ required: fields[0].required }"
        />
        
        <reg-input-text
          v-if="fields[1]"
          label="Middle Initial"
          :form="form"
          :field="fields[1]"
          placeholder="Enter Middle Initial"
          :api-errors="apiErrors"
          :rules="{ required: fields[1].required, alpha: true, max: 1 }"
          @inputHandler="handleMiddleInitialInput"
        />
      </div>
      <div class="form-group row">
        <reg-input-text
          v-if="fields[2]"
          label="Last Name"
          :form="form"
          :field="fields[2]"
          placeholder="Enter Last Name"
          :api-errors="apiErrors"
          :rules="{ required: fields[2].required }"
        />
        <reg-input-text
          v-if="fields[3] && regType==='individual'"
          label="SSN"
          :form="form"
          :field="fields[3]"
          placeholder="Enter SSN Number"
          :api-errors="apiErrors"
          :rules="{ required: fields[3].required, digits: 9 }"
          type="number"
          @inputHandler="handleSSNInput"
        />
      </div>
      <div class="form-group row">
        <reg-input-date-picker
          v-if="fields[4] && regType==='individual'"
          label="Date of Birth"
          :form="form"
          :field="fields[4]"
          :api-errors="apiErrors"
          placeholder="MM/DD/YYYY"
          date-format="MM/DD/YYYY"
          :rules="{ required: fields[4].required }"
        />

         <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4">
          <label v-if="fields[5]">What do you consider your main expertise in? <span class="required-color">*</span></label>
          <RegExpertiesSelect
              :form="form"
              :vmodel="form.experties_in"
              v-if="fields[5]"
              :field="fields[5]"
              name="state"
              labelName="Experties In"
              :api-errors="apiErrors"
              placeholder="Select Experties"
              :options="options.map((type) => type.code)"
              :custom-label="
                  (opt) => options.find((x) => x.code == opt).name
                "
              :rules="{ required: fields[5].required }"
          />
        </div>
      </div>
       <div class="form-group row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4">
          <label v-if="fields[6]">What products do you sell for us to serve you better in the future? <span class="required-color">*</span></label>
          <RegProductSelect
              :form="form"
              v-model="form.product_sold"
              v-if="fields[6]"
              :field="fields[6]"
              :api-errors="apiErrors"
              labelName="Product Sold"
              track-by="code"
              key="code"
              :options="products.map((type) => type.code)"
              :custom-label="
                  (opt) => products.find((x) => x.code == opt).name
                "
              placeholder="Select Product Sold"
              :rules="{ required: fields[6].required }"
              class="reg_product"
          />
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
          <label  v-if="fields[7]">How long have you been in the business? <span class="required-color">*</span></label>
          <RegBusinessSelect
              v-if="fields[7]"
              :field="fields[7]"
              labelName="business experience"
              :vmodel="form.years_in_business"
              :form="form"
              :api-errors="apiErrors"
              placeholder="Select Business Experience"
              :options="Years.map((type) => type.code)"
              :custom-label="
                  (opt) => Years.find((x) => x.code == opt).name
                "
              :rules="{ required: fields[7].required }"
          />
        </div>
      </div>
      <div class="action-btn split-action mt-3">
        <button class="button-back" @click="backHandler">Back</button>
        <div class="action-next">
          <button class="button-save" @click.prevent="saveAndContinue">
            Save & Continue Later
          </button>
          <button class="button-submit" type="submit">Next</button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import axios from "@/utils/regAxios";
import RegInputDatePicker from "../utils/RegInputDatePicker.vue";
import ScreenShot from "@/utils/screenshot";
import RegInputText from "../utils/RegInputText.vue";
import RegExpertiesSelect from "../utils/RegExpertiesSelect.vue";
import RegBusinessSelect from "../utils/RegBusinessSelect.vue";
import RegProductSelect from "../utils/RegProductSelect.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    RegBusinessSelect,
    RegExpertiesSelect,
    RegInputDatePicker,
    RegInputText,
    RegProductSelect
  },
  name: "PersonalForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
    selectedExperties: '',
    productSold: [],
    Year: '',
    options: [
        {
          name: "Financial Planner",
          code: "Financial Planner"
        },
        {
          name: "Group Benefit Broker",
          code: "Group Benefit Broker"
        },
        {
          name: "Property and Casulity Broker",
          code: "Property and Casulity Broker"
        },
        {
          name: "Life Insurance Broker",
          code: "Life Insurance Broker"
        }
      ],
    products: [
      {
        name: "Major Medical",
        code: "Major Medical"
      },
      {
        name: "Medicare Supplement",
        code: "Medicare Supplement"
      },
      {
        name: "Life Insurance",
        code: "Life Insurance"
      },
      {
        name: "Dental + Vision",
        code: "Dental + Vision"
      },
      {
        name: "Disability Income",
        code: "Disability Income"
      }
    ],
    Years: [
      {
        name:"1-5 years",
        code: "1-5"
      },
      {
        name: "6-10 years",
        code: "6-10"
      },
      {
        name:"11-20 years",
        code: "11-20"
      },
      {
        name: "20+ years",
        code: "20+"
      }
    ]
  }),
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      if(this.regType==="individual"){
        this.$emit('onBackPersonalInfoIndividualForm')
      }else{
 this.$emit("onBackPersonalInfoForm");
      }
     
    },
    handleSSNInput(event) {
    let inputValue = event.target.value;
    if (inputValue.length > 9) {
      inputValue = inputValue.slice(0, 9);
      this.form['ssn'] = inputValue;
    }
  },
    handleMiddleInitialInput(event) {
      let inputValue = event.target.value;
      inputValue = inputValue.replace(/[^A-Za-z]/g, '');
      inputValue = inputValue.slice(0, 1);
      this.form['middle_initial'] = inputValue;
    },
    submit() {
      let loader = this.$loading.show();
      this.form.registration_id = this.$store.getters.regTempId;
      this.form.savecont = false;
      let formData = this.form;
      if(this.regType==="business"){
        delete formData.ssn;
        delete formData.dob;
      }
      console.log("snad", formData)
      ScreenShot.captureScreenShot(ScreenShot.regScreenShotSelector)
        .then((res) => {
          if (res) {
            formData["screenshot"] = res;
          }
        })
        .finally(() => {
          loader.hide();
          const url = "v3/registration/personal-info";
          this.save(url, formData);
        });
    },
    save(url, payload) {
      let loader = this.$loading.show();
      axios
        .post(url, payload)
        .then(() => {
          this.$emit("onSuccessPersonalInfoForm");
        })
        .catch((err) => {
          if (err.response.data.statusCode == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  computed: {
    ...mapGetters({
      regType: "regType",
    }),
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
};
</script>
<style scoped>
.business-experience {
  margin-bottom: 31px;
}
.required-color {
  color: #ff0000;
}
</style>