var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "registrationWrapper container reg-wrapper"
  }, [_vm.tab !== 'Home-info' ? _c('div', [_vm._m(0), _c('div', {
    staticClass: "form-step"
  }, [_c('div', {}, [_c('h6', {
    staticClass: "mb-0"
  }, [_c('span', {
    staticClass: "broker-title"
  }, [_vm._v("Broker-Rep Registration Page ")]), _c('span', {
    staticClass: "step-count"
  }, [_vm._v("| Step " + _vm._s(_vm.tabCount) + " of "), this.regType == 'individual' ? _c('span', [_vm._v("8")]) : _c('span', [_vm._v("9")])])])]), _c('div', {
    staticClass: "detail-wrap detail-form-wrap"
  }, [_c('div', {
    staticClass: "contact-details"
  }, [_vm.info ? _c('div', {}, [_c('h6', [_c('i', {
    staticClass: "fas fa-address-card me-2 contact-icon"
  }), _vm._v("Direct Contact: " + _vm._s(_vm.info.first_name) + " " + _vm._s(_vm.info.last_name) + " ")])]) : _vm._e(), _vm.info ? _c('div', {}, [_c('h6', [_c('i', {
    staticClass: "fas fa-envelope me-2 email-icon"
  }), _vm._v("Email: "), _c('a', {
    attrs: {
      "href": "mailto:".concat(_vm.info.email)
    }
  }, [_vm._v(_vm._s(_vm.info.email))])])]) : _vm._e(), _vm.info ? _c('div', {}, [_c('h6', [_c('i', {
    staticClass: "fas fa-phone me-4 mb-2 pb-1 phone-icon"
  }), _vm._v("Phone: "), _c('a', {
    attrs: {
      "href": "tel:".concat(_vm.info.phone)
    }
  }, [_vm._v(_vm._s(_vm.info.phone))])])]) : _vm._e()]), _c('div', {
    staticClass: "bottom-line"
  })])])]) : _vm._e(), _c('div', [_vm.tab == 'Home-info' ? _c('home-form', {
    attrs: {
      "info": _vm.info,
      "errorMessage": _vm.errorMessage
    },
    on: {
      "onSetInfo": _vm.getInfo,
      "onSucessBrokerInfoIndividualForm": _vm.personalInfoHandler,
      "onSuccessBrokerInfoForm": _vm.businessInfoHandler
    }
  }) : _vm._e()], 1), _vm.tab !== 'Home-info' ? _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "whiteBG-wrap"
  }, [_c('div', {
    staticClass: "row registration-row"
  }, [_c('div', {
    staticClass: "col-xxl-12 col-xl-12 col-lg-12 col-md-12"
  }, [_c('div', {
    staticClass: "regForm-wrap"
  }, [_c('h2', [_vm._v(_vm._s(_vm.title))]), _c('hr'), _vm.tab == 'personal-info' ? _c('personal-form', {
    attrs: {
      "form": _vm.form,
      "fields": _vm.fields
    },
    on: {
      "onSuccessPersonalInfoForm": _vm.addressInfoHandler,
      "onBackPersonalInfoForm": _vm.businessInfoHandler,
      "onBackPersonalInfoIndividualForm": _vm.brokerInfoHandler,
      "onOpenSaveAndContinue": _vm.openSaveAndContinueModal
    }
  }) : _vm._e(), _vm.tab == 'address-info' ? _c('address-form', {
    attrs: {
      "form": _vm.form,
      "fields": _vm.fields,
      "states": _vm.states
    },
    on: {
      "update:states": function updateStates($event) {
        _vm.states = $event;
      },
      "onSuccessAddressInfoForm": _vm.loginInfoHandler,
      "onBackAddressInfoForm": _vm.personalInfoHandler,
      "onOpenSaveAndContinue": _vm.openSaveAndContinueModal
    }
  }) : _vm._e(), _vm.tab == 'login-info' ? _c('login-form', {
    attrs: {
      "form": _vm.form,
      "fields": _vm.fields
    },
    on: {
      "onSuccessLoginInfoForm": _vm.contactInfoHandler,
      "onBackLoginInfoForm": _vm.addressInfoHandler,
      "onOpenSaveAndContinue": _vm.openSaveAndContinueModal
    }
  }) : _vm._e(), _vm.tab == 'contact-info' ? _c('contact-form', {
    attrs: {
      "form": _vm.form,
      "fields": _vm.fields,
      "timezones": _vm.timezones
    },
    on: {
      "update:timezones": function updateTimezones($event) {
        _vm.timezones = $event;
      },
      "onSuccessContactInfoForm": _vm.paymentInfoHandler,
      "onBackContactInfoForm": _vm.loginInfoHandler,
      "onOpenSaveAndContinue": _vm.openSaveAndContinueModal
    }
  }) : _vm._e(), _vm.tab == 'business-info' ? _c('business-form', {
    attrs: {
      "form": _vm.form,
      "fields": _vm.fields,
      "states": _vm.states
    },
    on: {
      "onSuccessBusinessInfoForm": _vm.personalInfoHandler,
      "onBackBusinessInfoForm": _vm.brokerInfoHandler,
      "onOpenSaveAndContinue": _vm.openSaveAndContinueModal
    }
  }) : _vm._e(), _vm.tab == 'payment-info' ? _c('payment-form', {
    attrs: {
      "form": _vm.form,
      "fields": _vm.fields
    },
    on: {
      "onSuccessPaymentInfoForm": _vm.signatureInfoHandler,
      "onBackPaymentInfoForm": _vm.contactInfoHandler,
      "onBackPaymentInfoFormWithRegTypeIndividual": _vm.contactInfoHandler,
      "onOpenSaveAndContinue": _vm.openSaveAndContinueModal
    }
  }) : _vm._e(), _vm.tab == 'verification-info' ? _c('signature-form', {
    attrs: {
      "form": _vm.form,
      "fields": _vm.fields
    },
    on: {
      "onResetForm": _vm.resetForm,
      "onSuccessSignatureInfoForm": _vm.completeRegistration,
      "onBackSignatureInfoForm": _vm.paymentInfoHandler,
      "onOpenSaveAndContinue": _vm.openSaveAndContinueModal
    }
  }) : _vm._e()], 1)])])])]) : _vm._e(), _c('save-and-continue', {
    attrs: {
      "form": _vm.saveAndContinueData
    },
    on: {
      "update:form": function updateForm($event) {
        _vm.saveAndContinueData = $event;
      },
      "onSaveAndContinueHandler": _vm.resetForm,
      "onCloseModal": _vm.closeModal
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statement-txt pt-0 mb-5"
  }, [_c('h4', [_vm._v("Over 30 years of building relationships.")]), _c('h5', [_vm._v("One broker at a time.")])]);
}]

export { render, staticRenderFns }