<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group row mt-4">
          <reg-input-select
            v-if="fields[0] && timezones"
            label="Time zone"
            type="number"
            :form="form"
            :field="fields[0]"
            :options="timezones.map((type) => type.timezone)"
            :custom-label="
              (opt) =>
                timezones.find((x) => x.timezone == opt).timezone +
                ' (' +
                timezones.find((x) => x.timezone == opt).offset +
                ')'
            "
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-4"
            placeholder="Select Time zone"
            :api-errors="apiErrors"
            :rules="{ required: fields[0].required }"
          />
          <reg-input-text
            v-if="fields[1]"
            label="Mobile Number"
            type="number"
            :form="form"
            :field="fields[1]"
            placeholder="Enter Mobile Number"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[1].required, digits: 10 }"
            @inputHandler="handleInput($event, 'mobile_number')"
          />
          
        </div>
        <div class="form-group row">
      

          <reg-input-text
            v-if="fields[2]"
            label="Phone Number"
            type="number"
            :form="form"
            :field="fields[2]"
            placeholder="Enter Phone Number "
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[2].required, digits: 10 }"
            @inputHandler="handleInput($event, 'phone_number')"
          />
          <reg-input-text
            v-if="fields[3]"
            label="FAX Number"
            type="number"
            :form="form"
            :field="fields[3]"
            placeholder="Enter FAX Number"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[3].required, digits: 10 }"
            @inputHandler="handleInput($event, 'fax')"
          />
     
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 ">
            <div class="d-flex align-items-center mb-3">
              <input type="checkbox"  class="mb-1" v-model="isChecked"/>
              <label class="mb-0 ms-2">Display Phone</label> 
            </div>
            <div class="" v-if="isChecked">
            <reg-input-text
              v-if="fields[4]"
              label="Phone Number"
              type="number"
              :form="form"
              :field="fields[4]"
              placeholder="Enter Phone Number"
              :api-errors="apiErrors"
              formGroupClass="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-4"
              :rules="{ required: fields[2].required, digits: 10 }"
              @inputHandler="handleInput($event, 'display_phone')"
            />
          </div>
          </div>
          <!-- web access -->
          <reg-input-text
            v-if="fields[5]"
            label="Web Access"
            :form="form"
            :field="fields[5]"
            placeholder="Enter web access"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-4"
          />
        </div>
        

   
        <div class="action-btn split-action mt-5">
          <button class="button-back" @click="backHandler">Back</button>
          <div class="action-next">
            <button class="button-save" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-submit" type="submit">Next</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import axios from "@/utils/regAxios";
import RegInputRadio from "../utils/RegInputRadio.vue";
import ScreenShot from "@/utils/screenshot";

export default {
  name: "ContactForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
    timezones: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
    components: { RegInputRadio },
    isChecked: false
  }),
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackContactInfoForm");
    },
   
    handleInput(event, fieldName) {
      let inputValue = event.target.value;
      if (inputValue.length > 10) {
        inputValue = inputValue.slice(0, 10);
      }
      this.form[fieldName] = inputValue; 
    },
    submit() {
      if(this.isChecked && this.form.display_phone) {
        this.fields[4].value = this.form.display_phone;
        this.form.is_displayPhone = true;
      }else {
        this.form.display_phone = "";
        this.form.is_displayPhone = false;
        this.isChecked = false;
      }
      this.form.registration_id = this.$store.getters.regTempId;
      this.form.savecont = false;
      let loader = this.$loading.show();
      let formData = this.form;
      ScreenShot.captureScreenShot(ScreenShot.regScreenShotSelector)
        .then((res) => {
          if (res) {
            formData["screenshot"] = res;
          }
        })
        .finally(() => {
          loader.hide();
          const url = "v2/registration/contact-info";
          this.save(url, formData);
        });
    },
    save(url, payload) {
      let loader = this.$loading.show();
      axios
        .post(url, payload)
        .then(() => {
          this.$emit("onSuccessContactInfoForm");
        })
        .catch((err) => {
          if (err.response.data.statusCode == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  
  watch: {
    fields: {
      handler(newFields) {
        const isDisplayPhoneField = newFields.find(field => field.field_name === 'is_displayPhone');
        if (isDisplayPhoneField) {
          this.isChecked = isDisplayPhoneField.value === true;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
   
  },
};
</script>