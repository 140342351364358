import html2canvas from "html2canvas";

const REG_SCREENSHOT_SELECTOR = ".registration-row"; 

const Screenshot = {
  /**
   * query selector for registration screenshot
  */
  regScreenShotSelector : REG_SCREENSHOT_SELECTOR,
  /**
   * returns Promise
   * @param {String} selector 
   * @param {Object} options 
   */
  captureScreenShot: async function(selector = "body", options = {}){
    // return Promise
    return await html2canvas(document.querySelector(selector), options)
      .then((canvas) => {
        const imageUrl = canvas.toDataURL();
        return imageUrl;
      })
      .catch((err) => {
        console.error("Action Failed : ", err);
        return null;
      });
  },
};

export default Screenshot;