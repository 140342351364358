<template>
  <div class="registrationWrapper container reg-wrapper">
    <div v-if="tab !== 'Home-info'">
      <div class="statement-txt pt-0 mb-5">
        <h4>Over 30 years of building relationships.</h4>
        <h5>One broker at a time.</h5>
      </div>
      <div class="form-step">
      <div class="">
        <h6 class="mb-0"><span class="broker-title">Broker-Rep Registration Page </span><span class="step-count">| Step {{tabCount}} of  <span v-if="this.regType=='individual'">8</span> <span v-else>9</span> </span></h6>
      </div>
      <div class="detail-wrap detail-form-wrap">
        <div class="contact-details">
          <div class="" v-if="info">
            <h6>
              <i class="fas fa-address-card me-2 contact-icon"></i>Direct Contact:
              {{info.first_name}}  {{info.last_name}}
            </h6>
          </div>
          <div class="" v-if="info">
            <h6>
              <i class="fas fa-envelope me-2 email-icon"></i>Email:
              <a :href="`mailto:${info.email}`">{{info.email}}</a>
            </h6>
          </div>
          <div class="" v-if="info">
            <h6>
              <i class="fas fa-phone me-4 mb-2 pb-1 phone-icon"></i>Phone:
              <a :href="`tel:${info.phone}`">{{info.phone}}</a>
            </h6>
          </div>
        </div>
        <div class="bottom-line"></div>
      </div>
    </div>
  </div>
  
   <div>
     <home-form
         v-if="tab == 'Home-info'"
         :info="info"
         :errorMessage="errorMessage"
         @onSetInfo="getInfo"
         @onSucessBrokerInfoIndividualForm="personalInfoHandler"
         @onSuccessBrokerInfoForm="businessInfoHandler"/>
   </div>
    <div class="container"  v-if="tab !== 'Home-info'">
      <div class="whiteBG-wrap">
        <div class="row registration-row">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
            <div class="regForm-wrap">
              <!--Title-->
              <h2>{{ title }}</h2>
             
              <hr />
              <!--Form-->
<!--              <broker-form
                v-if="tab == 'broker-info'"
                :form="form"
                :fields="fields"
                @onSetInfo="getInfo"
                @onSuccessBrokerInfoForm="personalInfoHandler"
              />-->
              <personal-form
                v-if="tab == 'personal-info'"
                :form="form"
                :fields="fields"
                @onSuccessPersonalInfoForm="addressInfoHandler"
                @onBackPersonalInfoForm="businessInfoHandler"
                @onBackPersonalInfoIndividualForm="brokerInfoHandler"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <address-form
                v-if="tab == 'address-info'"
                :form="form"
                :fields="fields"
                :states.sync="states"
                @onSuccessAddressInfoForm="loginInfoHandler"
                @onBackAddressInfoForm="personalInfoHandler"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <login-form
                v-if="tab == 'login-info'"
                :form="form"
                :fields="fields"
                @onSuccessLoginInfoForm="contactInfoHandler"
                @onBackLoginInfoForm="addressInfoHandler"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <contact-form
                v-if="tab == 'contact-info'"
                :form="form"
                :fields="fields"
                :timezones.sync="timezones"
                @onSuccessContactInfoForm="paymentInfoHandler"
                @onBackContactInfoForm="loginInfoHandler"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <!-- <reg-type-form
                v-if="tab == 'registration-type'"
                :form="form"
                :fields="fields"
                :types.sync="regTypes"
                @onSuccessRegTypeForm="businessInfoHandler"
                @onBackRegTypeForm="contactInfoHandler"
                @onSuccessRegTypeIndividual="paymentInfoHandler"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              /> -->
              <business-form
                v-if="tab == 'business-info'"
                :form="form"
                :fields="fields"
                :states="states"
                @onSuccessBusinessInfoForm="personalInfoHandler"
                @onBackBusinessInfoForm="brokerInfoHandler"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <payment-form
                v-if="tab == 'payment-info'"
                :form="form"
                :fields="fields"
                @onSuccessPaymentInfoForm="signatureInfoHandler"
                @onBackPaymentInfoForm="contactInfoHandler"
                @onBackPaymentInfoFormWithRegTypeIndividual="contactInfoHandler"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <signature-form
                v-if="tab == 'verification-info'"
                :form="form"
                :fields="fields"
                @onResetForm="resetForm"
                @onSuccessSignatureInfoForm="completeRegistration"
                @onBackSignatureInfoForm="paymentInfoHandler"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
            </div>
          </div>
          <!-- <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5"> -->
          <!--Sidebar-->
          <!-- <registration-sidebar :info="info" />
          </div> -->
        </div>
        <!-- <span class="procecss-title">Rep Registration</span> -->
      </div>
    </div>

    <save-and-continue
      :form.sync="saveAndContinueData"
      @onSaveAndContinueHandler="resetForm"
      @onCloseModal="closeModal"
    />
  </div>
</template>
<script>
import axios from "@/utils/regAxios";
import newAxios from "axios";
//import BrokerForm from "./form/BrokerForm";
// import RegistrationSidebar from "./RegistrationSidebar.vue";
import HomeForm from "./HomeRegistration.vue";
import LoginForm from "./form/LoginForm.vue";
import PersonalForm from "./form/PersonalForm.vue";
import { mapGetters } from "vuex";
import ContactForm from "./form/ContactForm.vue";
import AddressForm from "./form/AddressForm.vue";
// import RegTypeForm from "./form/RegTypeForm.vue";
import BusinessForm from "./form/BusinessForm.vue";
import PaymentForm from "./form/PaymentForm.vue";
import SignatureForm from "./form/SignatureForm";
import SaveAndContinue from "./utils/SaveAndContinue.vue";
let axiosInstance = newAxios.create({ headers: "" });

export default {
  name: "Registration",
  components: {
    //BrokerForm,
    // RegistrationSidebar,
    HomeForm,
    PersonalForm,
    AddressForm,
    ContactForm,
    LoginForm,
    // RegTypeForm,
    BusinessForm,
    PaymentForm,
    SignatureForm,
    SaveAndContinue,
  },
  data: () => ({
    fields: [],
    form: {},
    states: [],
    saveAndContinueData: null,
    timezones: [],
    regTypes: [],
    info: null,
    errorMessage: '',
    nueraDefaultCode: 'NENY0000',
    dynamicColorList:[],
  }),
  computed: {
    ...mapGetters({
      formUrl: "regFormUrl",
      tab: "regTab",
      title: "regTitle",
      regTempId: "regTempId",
      regType: 'regType'
    }),
    isRepCodeRedirection () {
      return this.$store.getters.isRepCodeRedirection
    },
    repCode() {
      return this.$store.getters.repCode
    },
    tabCount () {
      return this.$store.getters.tabCount
    }
  },
  methods: {
    async getInfo(code = null) {

      if(this.$route.query.repCode == "5BHNJ457" ) {
        window.location = "https://reps.purenroll.com/registration?repCode=5BHFL457"
      }
      let url;
      url = `v2/rep-email-phone`;
      if (this.$route.query.repCode || this.$route.query.rep_code) {
        if (((this.$route.query.repCode || this.$route.query.rep_code) !== this.repCode)) {
          this.setData("Home-info", "Home Registration", "representative-info");
          this.$store.commit("setRegTempId", null);
        }
      } else {
        if ((this.repCode !== this.nueraDefaultCode)) {
          this.setData("Home-info", "Home Registration", "representative-info");
          this.$store.commit("setRegTempId", null);
        }
      }

      if (code) {
        url = url + `?rep_code=${code}`;
      } else if (this.$route.query.repCode) {
        let code = this.$route.query.repCode;
        url = url + `?rep_code=${code}`;
      } else if (this.$route.path == '/registration') {
        url = url + `?rep_code=${this.nueraDefaultCode}`;
      }
      await axiosInstance
        .get(url)
        .then((res) => {
          // const { phone, email } = { ...res.data.data };
          this.info = res.data.data
          localStorage.setItem('agent_id', this.info.agent_id)
          this.fetchDynamicColor()
          if (this.$route.query.repCode || this.$route.query.rep_code) {
            this.$store.commit("setRepCodeState", true);
            this.$store.commit("setRepCode", (this.$route.query.repCode || this.$route.query.rep_code )  || '')
          } else {
            this.$store.commit("setRepCodeState", false);
            this.$store.commit("setRepCode",  this.nueraDefaultCode);
          }

        })
        .catch((error) => {
          this.errorMessage = error.response.data.message
          this.setData("Home-info", "Home Registration", "representative-info");
          this.$store.commit("setRepCodeState", false);
          this.$store.commit("setRepCode", '');
          this.$store.commit("setRegTempId", null);
        });
    },
    setAgentCodeFromDownline() {
      this.$store.commit("setRegTempId", null);
      this.setData();
      this.getFormField();
      let agentField = this.fields[0].field_name;
      this.form[agentField] = this.$route.params.repCode; //agent_ga
    },
    getRegTypes() {
      axiosInstance
        .get("/v2/get-registration-types")
        .then((res) => {
          this.regTypes = res.data.data;
        })
        .catch(() => {
          this.regTypes = [];
        });
    },
    getTimezones() {
      axiosInstance
        .get("/v2/get-timezone")
        .then((res) => {
          this.timezones = res.data.data;
        })
        .catch(() => {
          this.timezones = [];
        });
    },
    completeRegistration() {
      this.$store.commit("setRegFormUrl", null);
      this.$store.commit("setRegTitle", null);
      this.$store.commit("setRegTab", null);
      this.$store.commit("setRegTempId", null);
      this.saveAndContinueData = null;
      this.$router.push({
        name: "RegisterSuccess",
        params: { canAccess: true },
      });
    },
    closeModal() {
      this.saveAndContinueData = null;
      this.form.savecont = false;
      this.$bvModal.hide("saveAndContinueModal");
    },
    openSaveAndContinueModal(data) {
      this.saveAndContinueData = null;
      if (data) {
        this.saveAndContinueData = data;
        this.$bvModal.show("saveAndContinueModal");
      }
    },
    fetchDynamicColor() {
      let userType= "A"
      newAxios.get(`v2/colorcoding/get-theme-list?user_id=${this.info.agent_id}&user_types=${userType}`, {
        headers: {
          'Authorization': process.env.VUE_APP_REG_API_KEY,
        }
      }).then(response => {
          this.dynamicColorList = response.data.data;
          this.dynamicColorList.forEach((variable, index) => {
            const colorValue = response.data.data[index].value;

          if (variable.key === "VUE_APP_VIDEO_LINK") {
              localStorage.setItem("videoLink", variable.value);
          }

          if (variable.key === "VUE_APP_VIDEO_BANNER_IMAGE") {
              let bannerLink = variable.value || "../../assets/images/banner-rep.png";
              localStorage.setItem("bannerImage", bannerLink);
          }
         
          const videoLink = localStorage.getItem("videoLink");
          const bannerImage = localStorage.getItem("bannerImage");

          if (videoLink && document.getElementById("video-source")) {
            document.getElementById("video-source").src = videoLink;
          }

          if (bannerImage && document.getElementById("video-poster")) {
            document.getElementById("video-poster").src = bannerImage;
          }
        if (variable.key) {
            document.documentElement.style.setProperty(`--${variable.key}`, colorValue);
        }
    })
  }).catch(error => {
        console.error("Error fetching dynamic color", error);
      });
    },
    resetForm() {
      this.$router.push('/registration')
      this.$store.commit("setRegFormUrl", null);
      this.$store.commit("setRegTitle", null);
      this.$store.commit("setRegTab", null);
      this.$store.commit("setRegTempId", null);
      this.$store.commit("setRepCode", null);
      this.saveAndContinueData = null;
      this.setData();
      this.getFormField();
      this.getInfo();
    },
    signatureInfoHandler() {
      const tabCount = this.regType==='individual'?'8':'9'
      this.setData(
        "verification-info",
        "Signature & Verification",
        "verification-info",
          tabCount
      );
      this.getFormField("getInfo");
    },
    paymentInfoHandler() {
      const tabCount = this.regType==='individual'?'7':'8'
      this.setData("payment-info", "Commission Payment Information", "payment-info", tabCount);
      this.getFormField("getInfo");
    },
    businessInfoHandler() {
      this.setData("business-info", "Business Information", "business-info", '2');
      this.getFormField("getInfo");
    },
    // regTypeInfoHandler() {
    //   this.setData(
    //     "registration-type",
    //     "Registration Type",
    //     "registration-type",
    //      '6'
    //   );
    //   this.getFormField("getInfo");
    // },
    addressInfoHandler() {
      const tabCount = this.regType==='individual'?'4':'5'
      this.setData("address-info", "Address Information", "address-info", tabCount);
      this.getFormField("getInfo");
    },
    contactInfoHandler() {
      const tabCount = this.regType==='individual'?'6':'7'
      this.setData("contact-info", "Broker Back Office Login Information", "contact-info", tabCount);
      this.getFormField("getInfo");
    },
    loginInfoHandler() {
      const tabCount = this.regType==='individual'?'5':'6'
      this.setData("login-info", "Login Information", "login-info", tabCount);
      this.getFormField("getInfo");
    },
    personalInfoHandler() {
      const tabCount = this.regType==='individual'?'2':'3'
      this.setData("personal-info", "Contact Information", "personal-info", tabCount);
      this.getFormField("getInfo");
    },
    brokerInfoHandler() {
      this.setData("Home-info", "Home Registration", "representative-info", '1');
      this.getFormField("getInfo");
    },
/*    setData(
      tab = "broker-info",
      title = "Broker Information",
      formUrl = "representative-info"
    ) {
      let url;
      if (tab == "personal-info") {
        url = `v3/registration/${formUrl}`;
      } else {
        url = `v2/registration/${formUrl}`;
      }
      this.$store.commit("setRegFormUrl", url);
      this.$store.commit("setRegTitle", title);
      this.$store.commit("setRegTab", tab);
    },*/
    setData(
        tab = "Home-info",
        title = "Home Registration",
        formUrl = "representative-info",
        tabCount = '1'
    ) {
      let url;
      if (tab == "personal-info") {
        url = `v3/registration/${formUrl}`;
      } else {
        url = `v2/registration/${formUrl}`;
      }
      this.$store.commit("setRegFormUrl", url);
      this.$store.commit("setRegTitle", title);
      this.$store.commit("setRegTab", tab);
      this.$store.commit("setTabCount", tabCount);
    },
    getStates() {
      let axiosInstance = newAxios.create({ headers: "" });
      axiosInstance
        .get("/v2/get-states")
        .then((res) => {
          this.states = res.data.data;
        })
        .catch(() => {
          this.states = [];
        });
    },
    getFormField(backBtnRedirection) {
      let url = this.$store.getters.regFormUrl;
      let regTempData = [];
      if (
        this.$store.getters.referredUserType == "existing-user" ||
        this.$store.getters.referredUserType === "" ||
        backBtnRedirection == "getInfo"
      ) {
        let regTempId = this.$store.getters.regTempId;
        if (regTempId) {
          regTempData = {
            registration_id: regTempId,
          };
        }
      }
      let loader = this.$loading.show();
      axios({
        method: "get",
        url: url,
        params: regTempData,
      })
        .then((res) => {
          this.fields = res.data.data;
          this.form = null;
          this.fields.map((data) => {
            this.form = { ...this.form, [data.field_name]: data.value };
          });
        })
        .catch(() => {
          this.form = null;
        })
        .finally(() => {
          loader.hide();
        });
    },
    getRepCodeFromReferralId(rid) {
      let url = "v2/get-referal-info";
      let loader = this.$loading.show();
      axios({
        method: "get",
        url: url,
        params: {
          rid: rid,
        },
      })
        .then((res) => {
          console.log(res);
          let agentField = this.fields[0].field_name;
          this.form[agentField] = res.data.data.agent_code;
          this.$store.commit("setReferralFlag", true);
          this.$store.commit("setReferralId", rid);
        })
        .catch(() => {
          this.form = null;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  
  /**
   * usercase repCode and rep_code : using repCode and rep_code as param and query respectively
   * repCode is used through registration route, will autopopulate in broker
   * rep_code is used through continue registration ,only auto populate if registration id matches
   */
  created() {
    if (!this.$store.getters.regTempId) {
      this.setData();
    }

    if (this.$route.query.repCode && this.tab == 'Home-info') {
      this.$store.commit("setRegTempId", null);
      this.$store.commit("setRepCodeState", true);
      this.setData();
    }

    if (
      this.$route.params.rid &&
      this.$store.getters.regTab !== "broker-info"
    ) {
      this.setData();
      if (this.$route.query.rid == this.$store.getters.referredId) {
        this.$store.commit("setReferralUserType", "existing-user");
      } else {
        this.$store.commit("setReferralUserType", "new-user");
      }
    }
    this.getFormField();
    this.getStates();
    this.getRegTypes();
    this.getTimezones();
    

    if (this.$route.query.rep_code) {
      this.getInfo(this.$route.query.rep_code);
      /**
       * removing string paramater from url
       */
      // this.$router.replace("/registration");
    } else {
      this.getInfo();
    }
  },
  watch: {
    form() {
      if (
        this.$route.params.repCode &&
        this.$store.getters.regTab == "broker-info"
      ) {
        let agentField = this.fields[0].field_name;
        this.form[agentField] = this.$route.params.repCode; //agent_ga
      }
      if (
        this.$route.params.rid &&
        this.$store.getters.regTab == "broker-info"
      ) {
        this.getRepCodeFromReferralId(this.$route.params.rid);
      }
    }
  },
};
</script>
<style src="@/assets/css/rep-registration.css"></style>