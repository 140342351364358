<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="form-group row mt-4">
        <reg-input-text
          v-if="fields[0]"
          label="Email Address"
          type="email"
          :form="form"
          :field="fields[0]"
          placeholder="Enter Email Address"
          :api-errors="apiErrors"
          formGroupClass="col-xxl-12 mb-4"
          :rules="{ required: fields[0].required, email: true }"
        />
      </div>
      <div class="d-flex align-items-center mb-3">
        <input type="checkbox"  class="mb-1" v-model="isChecked"/>
        <label class="mb-0 ms-2">Display Email Address</label>
      </div>
      <!-- additional email  -->
      <div class="form-group row mt-2"  v-if="isChecked">
        <reg-input-text
        v-if="fields[3]"
          label="Display Email Address"
          type="email"
          :form="form"
          :field="fields[3]"
          placeholder="Enter New Email Address"
          :api-errors="apiErrors"
          formGroupClass="col-xxl-12 mb-4"
        />

      </div>
      <div class="form-group row">
        <reg-input-text
          v-if="fields[1]"
          label="Password"
          type="password"
          :form="form"
          :field="fields[1]"
          placeholder="Password"
          :api-errors="apiErrors"
          formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
          :rules="{ required: fields[1].required }"
        >
          <template v-slot:appendIcon>
            <eye-button
              id="password"
              class-name="toggle-password-icon"
              :hide-on-default="true"
              type="password"
            />
          </template>
          <template v-slot:shortMessage>
            <span
              class="short-msg"
              v-if="!(apiErrors && apiErrors[fields[1].field_name])"
            >
              At least 8 Characters: One Uppercase Letter, One Lowercase Letter,
              One Number and One Special Character
            </span>
          </template>
        </reg-input-text>

        <reg-input-text
          v-if="fields[2]"
          label="Confirm Password"
          type="password"
          :form="form"
          :field="fields[2]"
          placeholder="Confirm Password"
          :api-errors="apiErrors"
          formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
          :rules="{ required: fields[2].required }"
        >
          <template v-slot:appendIcon>
            <eye-button
              id="password_confirmation"
              class-name="toggle-password-icon"
              :hide-on-default="true"
              type="password"
            />
          </template>
        </reg-input-text>
      </div>
      <div class="action-btn split-action mt-5">
        <button class="button-back" @click="backHandler">Back</button>
        <div class="action-next">
          <button class="button-save" @click.prevent="saveAndContinue">
            Save & Continue Later
          </button>
          <button class="button-submit" type="submit">Next</button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import axios from "@/utils/regAxios";
import ScreenShot from "@/utils/screenshot";

export default {
  name: "LoginForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
    isChecked: false
  }),
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackLoginInfoForm");
    },
    submit() {
      if(this.isChecked && this.form.display_email) {
        this.fields[4].value = this.form.display_email;
        this.form.is_displayEmail = true;
      }else {
        this.form.display_email = "";
        this.form.is_displayEmail = false;
        this.isChecked = false;
      }
      this.form.registration_id = this.$store.getters.regTempId;
      this.form.savecont = false;
      let loader = this.$loading.show();
      let formData = this.form;
      ScreenShot.captureScreenShot(ScreenShot.regScreenShotSelector)
        .then((res) => {
          if (res) {
            formData['screenshot'] = res;
          }
        })
        .finally(() => {
          loader.hide();
          const url = "v2/registration/login-info";
          this.save(url, formData);
        });
    },
    save(url , payload){
      let loader = this.$loading.show();
      axios
        .post(url, payload)
        .then(() => {
          // this.$notify(res.data.message);
          this.$emit("onSuccessLoginInfoForm");
        })
        .catch((err) => {
          if (err.response.data.statusCode == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        })
        .finally(() => {
          loader.hide();
        });
    }
  },
  watch: {
    fields: {
      handler(newFields) {
        const isDisplayEmailField = newFields.find(field => field.field_name === 'is_displayEmail');
        if (isDisplayEmailField) {
          this.isChecked = isDisplayEmailField.value === true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
};
</script>
