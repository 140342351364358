<template>
  <div class="hero-section">
    <div class="home-page-txt">
      <h4>Over 30 years of building relationships.</h4>
      <h5>One broker at a time.</h5>
    </div>
    <div class="home-reg">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-xxl-4 mb-5">
            <h6 class="broker-rep-txt">
              <span class="broker-title">Broker-Rep Registration Page </span
              ><span class="step-count"></span>
            </h6>
            <div class="reg-desc">
              <div class="mb-3"></div>
              <p>
                Hi, this is
                <span v-if="info">
                  {{ info.first_name }} {{ info.last_name }} </span
                >, welcome to the<br /><span class="broker-rep">
                  broker-Rep Registrations.</span
                ><br />
              </p>
              <h4><strong>Welcome from Nuera Benefits.</strong></h4>
              <div class="">
                <h4 class="broker-rep mt-4 pt-2">
                  <b>Select Registration Type</b>
                </h4>
                <div>
                  <button
                    class="com-reg btn-submit"
                    @click="clickHandler('business')"
                  >
                    <img src="@/assets/images/company.svg" />Company
                  </button>
                  <button
                    class="personal-reg btn-submit"
                    @click="clickHandler('individual')"
                  >
                    <img
                      src="@/assets/images/personal.svg"
                      class="me-2"
                    />Personal
                  </button>
                </div>
                <!--                <div @click="togglePlay" :class="{ active: !isPlaying }">
                  <div class="video-play">
                    <img src="../../assets/images/play.svg" /><span
                      >Watch Full Video</span
                    >
                  </div>
                </div>-->
              </div>
              <div class="mt-3 error-message">{{ errorMessage }}</div>
            </div>
          </div>
          <div class="col-md-7 col-xxl-8">
            <div class="detail-wrap">
              <div class="contact-details">
                <div class="" v-if="info">
                  <h6>
                    <i class="fas fa-address-card me-2 contact-icon"></i>Direct
                    Contact: {{ info.first_name }} {{ info.last_name }}
                  </h6>
                </div>
                <div class="" v-if="info">
                  <h6>
                    <i class="fas fa-envelope me-2 email-icon"></i>Email:
                    <a :href="`mailto:${info.email}`">{{ info.email }}</a>
                  </h6>
                </div>
                <div class="" v-if="info">
                  <h6>
                    <i class="fas fa-phone me-4 mb-2 pb-1 phone-icon"></i>Phone:
                    <a :href="`tel:${info.phone}`">{{ info.phone }}</a>
                  </h6>
                </div>
              </div>
              <div class="bottom-line"></div>
            </div>
            <!-- <video style="width: 150px;" src="../../assets/images/Sample.mp4"></video> -->
            <div class="banner-reg">
              <div class="video-player" @click="togglePlay">
                <img
                  class="thumbnail video-poster"
                  id="video-poster"
                  :src="bannerLink"
                  :class="{ active: !isPlaying }"
                  alt="Thumbnail"
                />
                <video ref="video" id="video-source" :poster="bannerLink">
                  <source :src="videoLink" type="video/mp4" />
                </video>
                <div class="play-button" :class="{ active: !isPlaying }">
                  <div class="play-btn-bg">
                    <i class="fas fa-play"></i>
                  </div>
                </div>
              </div>
              <!--              <img src="../../assets/images/banner-rep.png" />-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="note">
      Please note that after registration
      <span v-if="info"> {{ info.first_name }} {{ info.last_name }} </span> ,
      will contact you by phone for a quick conversation, then as soon as you
      activate you can start selling right away.
    </p>
  </div>
</template>

<script>
import axios from "@/utils/regAxios";
import ScreenShot from "@/utils/screenshot";
import { mapGetters } from "vuex";
export default {
  name: "HomeRegistration",
  props: {
    info: {
      required: true,
    },
    errorMessage: {
      required: true,
    },
    videoSrc: String, // Path to your video file
    thumbnailSrc: String, // Path to your thumbnail image
  },
  data: () => ({
    apiErrors: null,
    isPlaying: false,
    videoLink: "",
    bannerLink: "",
    contractId:null
  }),
  methods: {
    clickHandler(value) {
      this.save(value);
      let loader = this.$loading.show();
      let payloadTypeReg = {};
      payloadTypeReg["registration_id"] = this.$store.getters.regTempId;
      payloadTypeReg["registration_type"] = value;
      ScreenShot.captureScreenShot(ScreenShot.regScreenShotSelector)
        .then((res) => {
          if (res) {
            payloadTypeReg["screenshot"] = res;
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    setUserType(url, payload) {
      let loader = this.$loading.show();
      axios
        .post(url, payload)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },

    save(value) {
      const url = "v2/registration/representative-info";
      let payload = {};
      let regTempId = this.$store.getters.regTempId;
     
      if (this.$store.getters.isReferred) {
        payload["is_refeered"] = this.$store.getters.isReferred;
        (payload["referal_id"] = this.$store.getters.referredId),
          (payload["registration_id"] = regTempId);
      } else if (regTempId) {
        payload["agent_ga"] =
          (this.$route.query.repCode || this.$route.query.rep_code) ??
          "NENY0000";
        payload["is_refeered"] = false;
        payload["registration_id"] = regTempId;
      } else {
        payload["agent_ga"] =
          (this.$route.query.repCode || this.$route.query.rep_code) ??
          "NENY0000";
        payload["is_refeered"] = false;
      }
      if(this.contractId){
        payload['contract_id']= atob(this.contractId)
      }
    


      let loader = this.$loading.show();
      axios
        .post(url, payload)
        .then((res) => {
          this.$store.commit("setRegTempId", res.data.data.registration_id);
          let payloadTypeReg = {};
          payloadTypeReg["registration_id"] = res.data.data.registration_id;
          payloadTypeReg["registration_type"] = value;
          const url = "v2/registration/registration-type";
          this.setUserType(url, payloadTypeReg);
          this.$store.commit("setRegType", value);

          if (this.regType === "individual") {
            this.$emit("onSucessBrokerInfoIndividualForm");
          } else {
            this.$emit("onSuccessBrokerInfoForm");
            this.$emit("onSetInfo", this.form[this.fields[0].field_name]);
          }
          this.$store.commit("setContractId", null);
        })
        .catch((err) => {
          if (err.response.data.statusCode == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
          // this.$store.commit("setRegTempId", null);
          
        })
        .finally(() => {
          loader.hide();
        });
    },
    togglePlay() {
      const video = this.$refs.video;
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    getQueryParameters() {
      this.contractId = this.$route.query.contract;
    }
  },
  mounted() {
    this.videoLink =
      localStorage.getItem("videoLink") ||
      "https://rep-dashboard-theme.s3.us-east-2.amazonaws.com/video/NuEra.mp4";
    this.bannerLink =
      localStorage.getItem("bannerImage") ||
      "https://rep-dashboard-theme.s3.us-east-2.amazonaws.com/theme-assets/banner-rep.png";
  },
  created(){
    this.getQueryParameters();
  },
  watch: {
    '$route.query': 'getQueryParameters' 
  },
  computed: {
    ...mapGetters({
      regType: "regType",
    }),
  },
};
</script>
<style>
:root {
  --VUE_APP_REGISTRATION_START_VIDEO_PLAY_BUTTON_BACKGROUND_COLOR: #3737373e;
}
.video-container {
  position: relative;
  overflow: hidden;
}

.video-poster {
  width: 100%;
  height: auto;
  display: block;
  filter: brightness(0.7) blur(0px); /* Apply the shadow effect to the poster */
  transition: filter 0.3s;
}

.play-button {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(
    --VUE_APP_REGISTRATION_START_VIDEO_PLAY_BUTTON_BACKGROUND_COLOR
  );
  color: #fff;
  z-index: 99;
  padding: 2.5rem;
}
.play-btn-bg {
  background: #c6e4eb;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  padding: 1rem 1.2rem;
}

.video {
  display: none;
}

/* Show the video when the button is clicked */
.play-btn-bg :focus + .video {
  display: block;
}

/* Remove the shadow effect when the video is displayed */
.play-btn-bg :focus + .video + .video-poster {
  filter: none;
}

video {
  width: 100%;
}

.play-button,
.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.play-button.active,
.thumbnail.active {
  opacity: 1;
}

.thumbnail {
  z-index: 1; /* Place the thumbnail above the video */
}
.error-message {
  font-size: 18px;
  color: #dc3545;
  margin-top: 2px;
}
</style>
