var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('div', {
          staticClass: "form-group mb-4"
        }, [_c('reg-input-text', {
          attrs: {
            "label": "Type your full name which will be your \"signature\" to verify application terms and agreement",
            "labelName": "fullname",
            "form": _vm.form,
            "field": _vm.fields[0],
            "placeholder": "Enter Full Name",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-12",
            "rules": {
              required: _vm.fields[0].required
            }
          }
        })], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('reg-input-date-picker', {
          attrs: {
            "label": "Signature Date",
            "form": _vm.form,
            "field": _vm.fields[1],
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-4",
            "date-format": "MM/DD/YYYY",
            "rules": {
              required: _vm.fields[1].required
            }
          }
        })], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "signature"
        }, [_c('validation-provider', {
          attrs: {
            "name": "signature",
            "rules": {
              required: _vm.fields[2].required
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('label', [_vm._v("Signature")]), _c('VueSignaturePad', {
                ref: "signaturePad",
                style: _vm.apiErrors && _vm.apiErrors[_vm.fields[2].field_name] || validationContext.errors[0] ? ' border: 1px solid #dc3545;' : 'border: 1px solid rgb(206, 212, 218)',
                attrs: {
                  "width": "100%",
                  "height": "200px",
                  "options": {
                    onBegin: _vm.onBegin,
                    onEnd: _vm.onEnd
                  }
                },
                model: {
                  value: _vm.form[_vm.fields[2].field_name],
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, _vm.fields[2].field_name, $$v);
                  },
                  expression: "form[fields[2].field_name]"
                }
              }), _vm.apiErrors && _vm.apiErrors[_vm.fields[2].field_name] || validationContext.errors[0] ? _c('error-text', {
                attrs: {
                  "errors": _vm.apiErrors ? _vm.apiErrors : validationContext.errors ? validationContext.errors : '',
                  "field": _vm.fields[2].field_name
                }
              }) : _vm._e(), _c('div', {
                staticClass: "text-right",
                staticStyle: {
                  "text-align": "right"
                }
              }, [_c('a', {
                staticClass: "clearSignature",
                staticStyle: {
                  "background": "var(--VUE_APP_UNDO_LINK_BACKROUND_COLOR)",
                  "padding": "5px 25px",
                  "display": "inline-block",
                  "border-radius": "4px",
                  "margin-top": "10px",
                  "color": "var(--VUE_APP_UNDO_LINK_COLOR)",
                  "text-decoration": "none"
                },
                attrs: {
                  "href": "#"
                },
                on: {
                  "click": _vm.undo
                }
              }, [_vm._v(" Undo ")])])];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "action-btn split-action mt-5"
        }, [_c('button', {
          staticClass: "button-back",
          on: {
            "click": _vm.backHandler
          }
        }, [_vm._v("Back")]), _c('div', {
          staticClass: "action-next"
        }, [_c('button', {
          staticClass: "button-submit reset-btn",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetHandler.apply(null, arguments);
            }
          }
        }, [_vm._v(" Reset ")]), _c('button', {
          staticClass: "button-save",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveAndContinue.apply(null, arguments);
            }
          }
        }, [_vm._v(" Save & Continue Later ")]), _c('button', {
          staticClass: "button-save submit-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" Submit ")])])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }