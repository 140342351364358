var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-provider', {
    attrs: {
      "name": _vm.labelName.toLowerCase(),
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('div', {
          class: _vm.formGroupClass
        }, [_vm.label ? _c('label', [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.field && _vm.field.required ? _c('span', {
          staticClass: "required-indc"
        }, [_vm._v(" * ")]) : _vm._e()]) : _vm._e(), _vm._l(_vm.options, function (option) {
          return _c('div', {
            key: option.label,
            class: _vm.inputClass
          }, [_c('label', {
            staticClass: "custom-radio"
          }, [_vm._v(_vm._s(option.label) + " "), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.form[_vm.field.field_name],
              expression: "form[field.field_name]"
            }],
            attrs: {
              "id": _vm.field.field_name,
              "type": "radio",
              "disabled": _vm.field && _vm.field.readOnly
            },
            domProps: {
              "value": option.value,
              "checked": _vm._q(_vm.form[_vm.field.field_name], option.value)
            },
            on: {
              "input": _vm.inputHandler,
              "change": function change($event) {
                return _vm.$set(_vm.form, _vm.field.field_name, option.value);
              }
            }
          }), _c('span', {
            staticClass: "checkmark"
          })])]);
        }), _c('div', {
          staticClass: "col-md-12"
        }, [_vm.apiErrors && _vm.apiErrors[_vm.field.field_name] || validationContext.errors[0] ? _c('error-text', {
          attrs: {
            "errors": _vm.apiErrors ? _vm.apiErrors : validationContext.errors ? validationContext.errors : '',
            "field": _vm.field.field_name
          }
        }) : _vm._e()], 1)], 2)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }