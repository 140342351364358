var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-section"
  }, [_vm._m(0), _c('div', {
    staticClass: "home-reg"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5 col-xxl-4 mb-5"
  }, [_vm._m(1), _c('div', {
    staticClass: "reg-desc"
  }, [_c('div', {
    staticClass: "mb-3"
  }), _c('p', [_vm._v(" Hi, this is "), _vm.info ? _c('span', [_vm._v(" " + _vm._s(_vm.info.first_name) + " " + _vm._s(_vm.info.last_name) + " ")]) : _vm._e(), _vm._v(", welcome to the"), _c('br'), _c('span', {
    staticClass: "broker-rep"
  }, [_vm._v(" broker-Rep Registrations.")]), _c('br')]), _vm._m(2), _c('div', {}, [_vm._m(3), _c('div', [_c('button', {
    staticClass: "com-reg btn-submit",
    on: {
      "click": function click($event) {
        return _vm.clickHandler('business');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/company.svg")
    }
  }), _vm._v("Company ")]), _c('button', {
    staticClass: "personal-reg btn-submit",
    on: {
      "click": function click($event) {
        return _vm.clickHandler('individual');
      }
    }
  }, [_c('img', {
    staticClass: "me-2",
    attrs: {
      "src": require("@/assets/images/personal.svg")
    }
  }), _vm._v("Personal ")])])]), _c('div', {
    staticClass: "mt-3 error-message"
  }, [_vm._v(_vm._s(_vm.errorMessage))])])]), _c('div', {
    staticClass: "col-md-7 col-xxl-8"
  }, [_c('div', {
    staticClass: "detail-wrap"
  }, [_c('div', {
    staticClass: "contact-details"
  }, [_vm.info ? _c('div', {}, [_c('h6', [_c('i', {
    staticClass: "fas fa-address-card me-2 contact-icon"
  }), _vm._v("Direct Contact: " + _vm._s(_vm.info.first_name) + " " + _vm._s(_vm.info.last_name) + " ")])]) : _vm._e(), _vm.info ? _c('div', {}, [_c('h6', [_c('i', {
    staticClass: "fas fa-envelope me-2 email-icon"
  }), _vm._v("Email: "), _c('a', {
    attrs: {
      "href": "mailto:".concat(_vm.info.email)
    }
  }, [_vm._v(_vm._s(_vm.info.email))])])]) : _vm._e(), _vm.info ? _c('div', {}, [_c('h6', [_c('i', {
    staticClass: "fas fa-phone me-4 mb-2 pb-1 phone-icon"
  }), _vm._v("Phone: "), _c('a', {
    attrs: {
      "href": "tel:".concat(_vm.info.phone)
    }
  }, [_vm._v(_vm._s(_vm.info.phone))])])]) : _vm._e()]), _c('div', {
    staticClass: "bottom-line"
  })]), _c('div', {
    staticClass: "banner-reg"
  }, [_c('div', {
    staticClass: "video-player",
    on: {
      "click": _vm.togglePlay
    }
  }, [_c('img', {
    staticClass: "thumbnail video-poster",
    class: {
      active: !_vm.isPlaying
    },
    attrs: {
      "id": "video-poster",
      "src": _vm.bannerLink,
      "alt": "Thumbnail"
    }
  }), _c('video', {
    ref: "video",
    attrs: {
      "id": "video-source",
      "poster": _vm.bannerLink
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.videoLink,
      "type": "video/mp4"
    }
  })]), _c('div', {
    staticClass: "play-button",
    class: {
      active: !_vm.isPlaying
    }
  }, [_vm._m(4)])])])])])])]), _c('p', {
    staticClass: "note"
  }, [_vm._v(" Please note that after registration "), _vm.info ? _c('span', [_vm._v(" " + _vm._s(_vm.info.first_name) + " " + _vm._s(_vm.info.last_name) + " ")]) : _vm._e(), _vm._v(" , will contact you by phone for a quick conversation, then as soon as you activate you can start selling right away. ")])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home-page-txt"
  }, [_c('h4', [_vm._v("Over 30 years of building relationships.")]), _c('h5', [_vm._v("One broker at a time.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h6', {
    staticClass: "broker-rep-txt"
  }, [_c('span', {
    staticClass: "broker-title"
  }, [_vm._v("Broker-Rep Registration Page ")]), _c('span', {
    staticClass: "step-count"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h4', [_c('strong', [_vm._v("Welcome from Nuera Benefits.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h4', {
    staticClass: "broker-rep mt-4 pt-2"
  }, [_c('b', [_vm._v("Select Registration Type")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "play-btn-bg"
  }, [_c('i', {
    staticClass: "fas fa-play"
  })]);
}]

export { render, staticRenderFns }