var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row mt-4"
        }, [_vm.fields[0] && _vm.timezones ? _c('reg-input-select', {
          attrs: {
            "label": "Time zone",
            "type": "number",
            "form": _vm.form,
            "field": _vm.fields[0],
            "options": _vm.timezones.map(function (type) {
              return type.timezone;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.timezones.find(function (x) {
                return x.timezone == opt;
              }).timezone + ' (' + _vm.timezones.find(function (x) {
                return x.timezone == opt;
              }).offset + ')';
            },
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-4",
            "placeholder": "Select Time zone",
            "api-errors": _vm.apiErrors,
            "rules": {
              required: _vm.fields[0].required
            }
          }
        }) : _vm._e(), _vm.fields[1] ? _c('reg-input-text', {
          attrs: {
            "label": "Mobile Number",
            "type": "number",
            "form": _vm.form,
            "field": _vm.fields[1],
            "placeholder": "Enter Mobile Number",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[1].required,
              digits: 10
            }
          },
          on: {
            "inputHandler": function inputHandler($event) {
              return _vm.handleInput($event, 'mobile_number');
            }
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[2] ? _c('reg-input-text', {
          attrs: {
            "label": "Phone Number",
            "type": "number",
            "form": _vm.form,
            "field": _vm.fields[2],
            "placeholder": "Enter Phone Number ",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[2].required,
              digits: 10
            }
          },
          on: {
            "inputHandler": function inputHandler($event) {
              return _vm.handleInput($event, 'phone_number');
            }
          }
        }) : _vm._e(), _vm.fields[3] ? _c('reg-input-text', {
          attrs: {
            "label": "FAX Number",
            "type": "number",
            "form": _vm.form,
            "field": _vm.fields[3],
            "placeholder": "Enter FAX Number",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[3].required,
              digits: 10
            }
          },
          on: {
            "inputHandler": function inputHandler($event) {
              return _vm.handleInput($event, 'fax');
            }
          }
        }) : _vm._e(), _c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 "
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-3"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.isChecked,
            expression: "isChecked"
          }],
          staticClass: "mb-1",
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.isChecked) ? _vm._i(_vm.isChecked, null) > -1 : _vm.isChecked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.isChecked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = null,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && (_vm.isChecked = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.isChecked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.isChecked = $$c;
              }
            }
          }
        }), _c('label', {
          staticClass: "mb-0 ms-2"
        }, [_vm._v("Display Phone")])]), _vm.isChecked ? _c('div', {}, [_vm.fields[4] ? _c('reg-input-text', {
          attrs: {
            "label": "Phone Number",
            "type": "number",
            "form": _vm.form,
            "field": _vm.fields[4],
            "placeholder": "Enter Phone Number",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-4",
            "rules": {
              required: _vm.fields[2].required,
              digits: 10
            }
          },
          on: {
            "inputHandler": function inputHandler($event) {
              return _vm.handleInput($event, 'display_phone');
            }
          }
        }) : _vm._e()], 1) : _vm._e()]), _vm.fields[5] ? _c('reg-input-text', {
          attrs: {
            "label": "Web Access",
            "form": _vm.form,
            "field": _vm.fields[5],
            "placeholder": "Enter web access",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-4"
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "action-btn split-action mt-5"
        }, [_c('button', {
          staticClass: "button-back",
          on: {
            "click": _vm.backHandler
          }
        }, [_vm._v("Back")]), _c('div', {
          staticClass: "action-next"
        }, [_c('button', {
          staticClass: "button-save",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveAndContinue.apply(null, arguments);
            }
          }
        }, [_vm._v(" Save & Continue Later ")]), _c('button', {
          staticClass: "button-submit",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Next")])])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }