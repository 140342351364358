var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[0] ? _c('reg-input-text', {
          attrs: {
            "label": "Street Address 1",
            "form": _vm.form,
            "field": _vm.fields[0],
            "placeholder": "Enter address",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[0].required
            }
          }
        }) : _vm._e(), _vm.fields[1] ? _c('reg-input-text', {
          attrs: {
            "label": "Apt / Suite / Other",
            "form": _vm.form,
            "field": _vm.fields[1],
            "placeholder": "Enter Apt / Suite / Other",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[1].required
            }
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[2] ? _c('reg-input-text', {
          attrs: {
            "label": "City",
            "form": _vm.form,
            "field": _vm.fields[2],
            "placeholder": "Enter City",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[2].required
            }
          }
        }) : _vm._e(), _vm.fields[3] ? _c('reg-input-select', {
          attrs: {
            "label": "State",
            "form": _vm.form,
            "field": _vm.fields[3],
            "options": _vm.states.map(function (type) {
              return type.abbrev;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.states.find(function (x) {
                return x.abbrev == opt;
              }).name;
            },
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "placeholder": "Choose a State",
            "api-errors": _vm.apiErrors,
            "rules": {
              required: _vm.fields && _vm.fields[3] ? _vm.fields[3].required : false
            }
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[4] ? _c('reg-input-text', {
          attrs: {
            "label": "Zip Code",
            "form": _vm.form,
            "field": _vm.fields[4],
            "placeholder": "Enter Zip Code",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[4].required,
              digits: 5
            },
            "type": "number"
          },
          on: {
            "inputHandler": _vm.handleZIPInput
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group"
        }, [_c('div', {
          staticClass: "mb-4 form-check"
        }, [_vm.fields[5] && _vm.fields[5].field_name ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form[_vm.fields[5].field_name],
            expression: "form[fields[5].field_name]"
          }],
          staticClass: "form-check-input",
          class: _vm.apiErrors && _vm.apiErrors[_vm.fields[5].field_name] ? 'error-input' : '',
          attrs: {
            "type": "checkbox",
            "id": "flexCheckDefault",
            "disabled": _vm.fields[5] && _vm.fields[5].readOnly
          },
          domProps: {
            "checked": Array.isArray(_vm.form[_vm.fields[5].field_name]) ? _vm._i(_vm.form[_vm.fields[5].field_name], null) > -1 : _vm.form[_vm.fields[5].field_name]
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.form[_vm.fields[5].field_name],
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = null,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.form, _vm.fields[5].field_name, $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.form, _vm.fields[5].field_name, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.form, _vm.fields[5].field_name, $$c);
              }
            }
          }
        }) : _vm._e(), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "flexCheckDefault"
          }
        }, [_vm._v(" Validate with USPS ")]), _vm.apiErrors && _vm.apiErrors[_vm.fields[5].field_name] ? _c('error-text', {
          attrs: {
            "errors": _vm.apiErrors,
            "field": _vm.fields[5].field_name
          }
        }) : _vm._e()], 1)]), _c('div', {
          staticClass: "action-btn split-action mt-5"
        }, [_c('button', {
          staticClass: "button-back",
          on: {
            "click": _vm.backHandler
          }
        }, [_vm._v("Back")]), _c('div', {
          staticClass: "action-next"
        }, [_c('button', {
          staticClass: "button-save",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveAndContinue.apply(null, arguments);
            }
          }
        }, [_vm._v(" Save & Continue Later ")]), _c('button', {
          staticClass: "button-submit",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Next")])])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }