<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <reg-input-radio
          v-if="fields[0]"
          label="Pay To"
          labelName="pay to"
          :form="form"
          :field="fields[0]"
          :api-errors="apiErrors"
          :options="payToOptions"
          :rules="{ required: fields[0].required }"
        />
        <reg-input-radio
          v-if="fields[1]"
          label="How do you want to be paid?"
          labelName="pay by"
          :form="form"
          :field="fields[1]"
          :api-errors="apiErrors"
          :options="payByOptions"
          :rules="{ required: fields[1].required }"
        />

        <template
          v-if="form[fields[1] ? fields[1].field_name : 'pay_by'] == 'ach'"
        >
          <div class="form-group row">
            <reg-input-text
              label="Personal Name"
              :form="form"
              :field="fields[2]"
              placeholder="Enter Personal Name"
              :api-errors="apiErrors"
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
              :rules="{ required: fields[2].required }"
            />
            <reg-input-text
              v-if="fields[4]"
              label="Routing Number "
              type="number"
              :form="form"
              :field="fields[4]"
              placeholder="Enter Routing Number"
              :api-errors="apiErrors"
              @inputHandler="updateRoutingNumber"
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
              :rules="{ required: fields[4].required, digits: 9 }"
            />
          </div>

          <div class="form-group row">
            <reg-input-text
              v-if="fields[5]"
              label="Bank Name"
              :form="form"
              :field="fields[5]"
              placeholder="Enter Bank Name"
              :api-errors="apiErrors"
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
              :rules="{ required: fields[5].required }"
            />
            <reg-input-text
              v-if="fields[3]"
              label="Account Number"
              :form="form"
              :field="fields[3]"
              placeholder="Enter Account Number"
              :api-errors="apiErrors"
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
              :rules="{ required: fields[3].required, min: 4, max: 17 }"
            />
          </div>

          <div class="form-group row">
            <reg-input-select
              v-if="fields[6]"
              label="Account Type"
              :form="form"
              :field="fields[6]"
              :options="accountTypes.map((type) => type.value)"
              :custom-label="
                (opt) => accountTypes.find((x) => x.value == opt).name
              "
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
              placeholder="Choose Account Type"
              :api-errors="apiErrors"
              :rules="{ required: fields[6].required }"
            />

            <reg-input-select
              v-if="fields[7]"
              label="Account Holder Type"
              :form="form"
              :field="fields[7]"
              :options="accountHolderTypes.map((type) => type.value)"
              :custom-label="
                (opt) => accountHolderTypes.find((x) => x.value == opt).name
              "
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
              placeholder="Choose Account Holder Type"
              :api-errors="apiErrors"
              :rules="{ required: fields[7].required }"
            />
          </div>
        </template>
        <div class="action-btn split-action payment-pt">
          <button class="button-back" @click="checkRegTypeAndBackHandler">
            Back
          </button>
          <div class="action-next">
            <button class="button-save" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-submit" type="submit">Next</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import newAxios from 'axios'
import axios from '@/utils/regAxios'
import RegInputRadio from '../utils/RegInputRadio.vue'
import ScreenShot from '@/utils/screenshot'

export default {
  name: 'PaymentForm',
  components: { RegInputRadio },
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
    accountHolderTypes: [
      { name: 'Personal name', value: 'Personal name' },
      { name: 'Company', value: 'company' },
    ],
    accountTypes: [
      { name: 'Checking', value: 'checking' },
      { name: 'Savings', value: 'savings' },
    ],
    payToOptions: [
      { label: 'Personal name', value: 'individual' },
      { label: 'Company name', value: 'business' },
    ],
    payByOptions: [
      { label: 'Supply bank information at a later date', value: 'check' },
      { label: 'Ach', value: 'ach' },
    ],
  }),
  methods: {
    saveAndContinue() {
      this.$emit('onOpenSaveAndContinue', this.form)
    },
    submit() {
      this.form.registration_id = this.$store.getters.regTempId
      let loader = this.$loading.show()
      let payBy = this.fields[1].field_name
      let formData = this.form
      if (this.form[payBy] == 'check') {
        formData = (({ registration_id, pay_by, pay_to }) => ({
          registration_id,
          pay_by,
          pay_to,
        }))(formData)
      }
      ScreenShot.captureScreenShot(ScreenShot.regScreenShotSelector)
        .then((res) => {
          if (res) {
            formData['screenshot'] = res
          }
        })
        .finally(() => {
          loader.hide()
          const url = 'v2/registration/payment-info'
          this.save(url, formData)
        })
    },
    save(url, payload) {
      let loader = this.$loading.show()
      axios
        .post(url, payload)
        .then(() => {
          // this.$notify(res.data.message);
          this.$emit('onSuccessPaymentInfoForm')
        })
        .catch((err) => {
          if (err.response.data.statusCode == 422) {
            this.apiErrors = err.response.data.errors
            setTimeout(() => {
              this.apiErrors = null
            }, 5000)
          } else {
            this.$notify(err.response.data.message, 'error')
          }
        })
        .finally(() => {
          loader.hide()
        })
    },
    customRoutingError(message) {
      this.apiErrors = {
        routing_number: [message],
      }
    },
    updateRoutingNumber(event) {
      let url, pattern, routingNumber
      let inputValue = event.target.value
      if (inputValue.length > 9) {
        inputValue = inputValue.slice(0, 9)
        this.form['routing_number'] = inputValue
      }
      pattern = new RegExp('^\\d{9}$')
      routingNumber = this.form[this.fields[4].field_name]
      if (routingNumber.match(pattern)) {
        url = `${this.$endpoint.VALIDATE_ROUTING_NUMBER_NEW}${routingNumber}`
        const otherUrl = newAxios.create({})
        otherUrl
          .get(url)
          .then((res) => {
            if (res.data.statusCode == 200) {
              this.form[this.fields[5].field_name] = res.data.data.name
            } else {
              this.form[this.fields[5].field_name] = ''
              this.customRoutingError('Invalid Routing Number')
            }
          })
          .catch(() => {
            this.customRoutingError('Invalid Routing Number')
            this.form[this.fields[5].field_name] = ''
          })
          .finally(() => {
            setTimeout(() => {
              this.apiErrors = null
            }, 5000)
          })
      } else {
        this.customRoutingError(
          'The Routing Number field must be numeric and exactly contain 9 digits',
        )
        setTimeout(() => {
          this.apiErrors = null
        }, 5000)
      }
    },
    getAccountTypes() {
      newAxios
        .get('/v2/get-account-types')
        .then((res) => {
          this.accountTypes = res.data.data
        })
        .catch(() => {
          this.accountTypes = []
        })
    },
    getAccountHolderTypes() {
      newAxios
        .get('/v2/get-account-holder-types')
        .then((res) => {
          this.accountHolderTypes = res.data.data
        })
        .catch(() => {
          this.accountHolderTypes = []
        })
    },
    checkRegTypeAndBackHandler() {
      let url = 'v2/registration/registration-type'
      let regTempData = {
        registration_id: this.$store.getters.regTempId,
      }
      axios({
        method: 'get',
        url: url,
        params: regTempData,
      })
        .then((res) => {
          const data = res.data.data
          let regType = data[0]
          if (regType && regType.value === 'individual') {
            this.$emit('onBackPaymentInfoFormWithRegTypeIndividual')
          } else {
            this.$emit('onBackPaymentInfoForm')
          }
        })
        .catch(() => {
          console.log('err')
        })
    },
  },
  created() {
    this.getAccountTypes()
    this.getAccountHolderTypes()
  },
  // watch: {
  //   form: {
  //     handler(val) {
  //       if (val.pay_by == "check") {
  //       }
  //     },
  //     deep: true,
  //   },
  // },
}
</script>
