var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_vm.fields[0] ? _c('reg-input-radio', {
          attrs: {
            "label": "Pay To",
            "labelName": "pay to",
            "form": _vm.form,
            "field": _vm.fields[0],
            "api-errors": _vm.apiErrors,
            "options": _vm.payToOptions,
            "rules": {
              required: _vm.fields[0].required
            }
          }
        }) : _vm._e(), _vm.fields[1] ? _c('reg-input-radio', {
          attrs: {
            "label": "How do you want to be paid?",
            "labelName": "pay by",
            "form": _vm.form,
            "field": _vm.fields[1],
            "api-errors": _vm.apiErrors,
            "options": _vm.payByOptions,
            "rules": {
              required: _vm.fields[1].required
            }
          }
        }) : _vm._e(), _vm.form[_vm.fields[1] ? _vm.fields[1].field_name : 'pay_by'] == 'ach' ? [_c('div', {
          staticClass: "form-group row"
        }, [_c('reg-input-text', {
          attrs: {
            "label": "Personal Name",
            "form": _vm.form,
            "field": _vm.fields[2],
            "placeholder": "Enter Personal Name",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[2].required
            }
          }
        }), _vm.fields[4] ? _c('reg-input-text', {
          attrs: {
            "label": "Routing Number ",
            "type": "number",
            "form": _vm.form,
            "field": _vm.fields[4],
            "placeholder": "Enter Routing Number",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[4].required,
              digits: 9
            }
          },
          on: {
            "inputHandler": _vm.updateRoutingNumber
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[5] ? _c('reg-input-text', {
          attrs: {
            "label": "Bank Name",
            "form": _vm.form,
            "field": _vm.fields[5],
            "placeholder": "Enter Bank Name",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[5].required
            }
          }
        }) : _vm._e(), _vm.fields[3] ? _c('reg-input-text', {
          attrs: {
            "label": "Account Number",
            "form": _vm.form,
            "field": _vm.fields[3],
            "placeholder": "Enter Account Number",
            "api-errors": _vm.apiErrors,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "rules": {
              required: _vm.fields[3].required,
              min: 4,
              max: 17
            }
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "form-group row"
        }, [_vm.fields[6] ? _c('reg-input-select', {
          attrs: {
            "label": "Account Type",
            "form": _vm.form,
            "field": _vm.fields[6],
            "options": _vm.accountTypes.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.accountTypes.find(function (x) {
                return x.value == opt;
              }).name;
            },
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "placeholder": "Choose Account Type",
            "api-errors": _vm.apiErrors,
            "rules": {
              required: _vm.fields[6].required
            }
          }
        }) : _vm._e(), _vm.fields[7] ? _c('reg-input-select', {
          attrs: {
            "label": "Account Holder Type",
            "form": _vm.form,
            "field": _vm.fields[7],
            "options": _vm.accountHolderTypes.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.accountHolderTypes.find(function (x) {
                return x.value == opt;
              }).name;
            },
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
            "placeholder": "Choose Account Holder Type",
            "api-errors": _vm.apiErrors,
            "rules": {
              required: _vm.fields[7].required
            }
          }
        }) : _vm._e()], 1)] : _vm._e(), _c('div', {
          staticClass: "action-btn split-action payment-pt"
        }, [_c('button', {
          staticClass: "button-back",
          on: {
            "click": _vm.checkRegTypeAndBackHandler
          }
        }, [_vm._v(" Back ")]), _c('div', {
          staticClass: "action-next"
        }, [_c('button', {
          staticClass: "button-save",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveAndContinue.apply(null, arguments);
            }
          }
        }, [_vm._v(" Save & Continue Later ")]), _c('button', {
          staticClass: "button-submit",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Next")])])])], 2)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }