<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group mb-4">
          <reg-input-text
            label='Type your full name which will be your "signature" to verify application terms and agreement'
            labelName="fullname"
            :form="form"
            :field="fields[0]"
            placeholder="Enter Full Name"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-12"
            :rules="{ required: fields[0].required }"
          />
        </div>

        <div class="form-group row">
          <reg-input-date-picker
            label="Signature Date"
            :form="form"
            :field="fields[1]"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-4"
            date-format="MM/DD/YYYY"
            :rules="{ required: fields[1].required }"
          />
        </div>

        <div class="form-group row">
          <div class="signature">
            <validation-provider
              name="signature"
              :rules="{ required: fields[2].required }"
              v-slot="validationContext"
            >
              <label>Signature</label>
              <VueSignaturePad
                width="100%"
                height="200px"
                ref="signaturePad"
                v-model="form[fields[2].field_name]"
                :options="{ onBegin, onEnd }"
                :style="
                  (apiErrors && apiErrors[fields[2].field_name]) ||
                  validationContext.errors[0]
                    ? ' border: 1px solid #dc3545;'
                    : 'border: 1px solid rgb(206, 212, 218)'
                "
              />
              <error-text
                v-if="
                  (apiErrors && apiErrors[fields[2].field_name]) ||
                  validationContext.errors[0]
                "
                :errors="
                  apiErrors
                    ? apiErrors
                    : validationContext.errors
                    ? validationContext.errors
                    : ''
                "
                :field="fields[2].field_name"
              />
              <div class="text-right" style="text-align: right">
                <a
                  href="#"
                  @click="undo"
                  class="clearSignature"
                  style="
                    background: var(--VUE_APP_UNDO_LINK_BACKROUND_COLOR);
                    padding: 5px 25px;
                    display: inline-block;
                    border-radius: 4px;
                    margin-top: 10px;
                    color: var(--VUE_APP_UNDO_LINK_COLOR);
                    text-decoration: none;
                  "
                >
                  Undo
                </a>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="action-btn split-action mt-5">
          <button class="button-back" @click="backHandler">Back</button>
          <div class="action-next">
            <button class="button-submit reset-btn" @click.prevent="resetHandler">
              Reset
            </button>
            <button class="button-save" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-save submit-btn" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import axios from "@/utils/regAxios";
import RegInputDatePicker from "../utils/RegInputDatePicker.vue";
import ScreenShot from "@/utils/screenshot";

export default {
  name: "SignatureForm",
  components: { RegInputDatePicker },
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
  }),
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    resetHandler() {
      this.$confirm({
        title: "Reset Form",
        message: `Do you want to reset this form?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$emit("onResetForm");
          }
        },
      });
    },
    submit() {
      this.form.registration_id = this.$store.getters.regTempId;
      let formData = this.form;
      let loader = this.$loading.show();
      ScreenShot.captureScreenShot(ScreenShot.regScreenShotSelector)
        .then((res) => {
          if (res) {
            formData["screenshot"] = res;
          }
        })
        .finally(() => {
          loader.hide();
          const url = "v2/registration/verification-info";
          this.save(url, formData);
        });
    },
    save(url, payload) {
      let loader = this.$loading.show();
      axios
        .post(url, payload)
        .then((res) => {
          this.$notify(res.data.message);
          this.$emit("onSuccessSignatureInfoForm");
        })
        .catch((err) => {
          if (err.response.data.statusCode == 422) {
            this.apiErrors = err.response.data.errors;
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          } else {
            this.$notify(err.response.data.message, "error");
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas();
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        let field = this.fields[2].field_name;
        this.form[field] = data;
      }
    },
    backHandler() {
      this.$emit("onBackSignatureInfoForm");
    },
  },
};
</script>
<style>
 :root{
  --VUE_APP_UNDO_LINK_BACKROUND_COLOR:#0ab5e0;
  --VUE_APP_UNDO_LINK_COLOR:#fff;
 }
</style>
 